import React from 'react';
import { PageProps, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { WithTranslation, withTranslation } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import PageMenu from '../../../components/PageMenu/PageMenu';
import mortgageResourcesMenu from '../../../data/page-menus/mortgage-resources';
import MortgageServicingDocuments from '../../../components/MortgageServicingDocuments/MortgageServicingDocuments';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import ButtonLink, { ButtonLinkSize } from '../../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../../components/Icon/Icon';
import './style.scss';

type Props = PageProps & WithTranslation;

const MortgageServiceDocuments: React.FC<Props> = (props: Props) => {
  const { t, data } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('mortgageServiceDocuments.seo.title'),
    },
    page: {
      title: t('mortgageServiceDocuments.heading'),
    },
    heroSection: {
      options: {
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Hero Image',
        },
        accentColor: HeroSectionColors.SKY_BLUE,
      },
    },
    shouldEnableMaxWidth: false,
  };

  return (
    <Layout
      options={layoutOptions}
      className="MortgageServiceDocuments MortgageServiceDocuments__header-max-width-container"
    >
      <div className="grid-container">
        <div className="row row-wrap MortgageServiceDocuments__max-width-container MortgageServiceDocuments__generic-gap">
          <section className="column column-60">
            <p className="MortgageServiceDocuments__body-generic-gap">
              {t('mortgageServiceDocuments.introParagraph')}
            </p>
            <MortgageServicingDocuments />
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={mortgageResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.mortgageResources`)}
            />
          </aside>
        </div>
        <div className="row row-wrap MortgageServiceDocuments__footer-block">
          <GatsbyImage
            alt="Image"
            fixed={(data as any).footerImage.childImageSharp.fixed}
            style={{ position: 'relative', width: '100%', height: '320px', display: 'block' }}
            imgStyle={{ backgroundSize: 'cover', backgroundPosition: 'center', height: '320px' }}
          />

          <div className="MortgageServiceDocuments__footer-block-container">
            <div className="MortgageServiceDocuments__footer-block-text-block">
              <h2>{t('mortgageServiceDocuments.footerBlock.heading')}</h2>
              <p>{t('mortgageServiceDocuments.footerBlock.description')}</p>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                styleOptions={{ isInline: true, size: ButtonLinkSize.SMALL }}
              >
                {t('mortgageServiceDocuments.footerBlock.buttonLabel')}{' '}
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    footerImage: file(relativePath: { eq: "generic-image-3.jpg" }) {
      childImageSharp {
        fixed(width: 1400, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(MortgageServiceDocuments);
